import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
  Grid,

} from "@material-ui/core";
import { Card, CardBody } from 'reactstrap';
import { Tabs } from "antd";
import NotificationDescription from "../Notifications/NotificationDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import OnlineCertificates from "./OnlineCertificates";
import { Library } from "../PanduFacility/FacilityDescription";
import { PostData } from "../../api/service";


class StudentDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        
        {this.props.type == "notification" ? (
          <>
          <BreadCrumbOwn title="Student Notifications" />
          <NotificationDescription code="COLLEGE_ACADEMIC_NOTICE" title="Student Notifications" />
          </>
        ) : null}
        {this.props.type == "downloads" ? (
          <>
          <BreadCrumbOwn title="Student Notifications" />
          <NotificationDescription code="COLLEGE_DOWNLOADS" title="Student Downloads" />
          </>
        ) : null}
        {this.props.type == "downloads-general" ? (
          <>
          <BreadCrumbOwn title="Notifications" />
          <NotificationDescription code="COLLEGE_DOWNLOADS_GENERAL" title="General Downloads" />
          </>
        ) : null}
        {this.props.type == "routines" ? <>
          <BreadCrumbOwn title="Student Notifications" /><NotificationDescription code="CLASS_ROUTINES" title="Class Routines" /></> : null}
        {this.props.type == "results" ? <><BreadCrumbOwn title="Student Notifications" /><NotificationDescription code="EXAM_RESULTS" title="Exam Results" /></> : null}
        {this.props.type == "exam-schedule" ? <><BreadCrumbOwn title="Student Notifications" /><NotificationDescription code="EXAM_SCHEDULE" title="Exam Schedule" /></> : null}
        {this.props.type == "clubs" ? <><BreadCrumbOwn title="Student Notifications" /><NotificationDescription code="STUDENT_CLUBS" title="Student Clubs" /></> : null}
        

        {this.props.type == 'students_union' ? (<><BreadCrumbOwn title="Student's Union" /><GoverningBody title="Student's Union" type="PCSU" /></>) : null}


        {this.props.type == "certificates" ? <><BreadCrumbOwn title="Apply for certificate" /><OnlineCertificates /></> : null}
      </div>
    );
  }
}

export default StudentDescription;

export const Notification = (props) => {
  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        {props.title}
      </Typography>
      <hr />

      <Typography variant="body1" gutterBottom>
        notifications
      </Typography>
    </div>
  );
};

export const Downloads = (props) => {
  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        {props.title}
      </Typography>
      <hr />

      <Typography variant="body1" gutterBottom>
        Downloads
      </Typography>
    </div>
  );
};

const { TabPane } = Tabs;



export class GoverningBody extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  componentDidMount() {
    PostData(`/getmembersbytype`, {
      type: this.props.type
    }).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        

        <Container className="mb-5">
      

          <div>
            <Typography
              variant="h5"
              style={{ margin: "1em 0", fontWeight: 700 }}
              gutterBottom
            >
              {this.props.title}
            </Typography>

            <br />

            <div>
              {this.state.isLoaded && (
                <>
                  <Grid container spacing={2}>
                    {this.state.data.map((el, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                        <Card>
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {Array.isArray(el.dp_url) &&
                                el.dp_url.map((el1) => (
                                  <img src={el1} style={{ width: 150, height: 150, objectFit: 'cover' }} />
                                ))}
                            </div>
                            <div
                              style={{
                                paddingLeft: 20,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                {el.name}
                              </Typography>
                              <Typography variant="overline">
                                PORTFOLIO
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                {el.designation}
                              </Typography>
                              <br />
                              {el.email && <><Typography variant="overline">
                                CONTACT
                              </Typography>
                              <Typography variant="body1">
                                <a href={`mailto:${el.email.toLowerCase()}`}>
                                  {el.email.toLowerCase()}
                                </a>
                              </Typography></>}

                              
                            </div>

                            <div
                              style={{
                                paddingLeft: 20,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginTop: 40
                              }}
                            >
                              

                              
                              {el.phone && <><Typography variant="overline">
                                PHONE
                              </Typography>
                              <Typography variant="body1">
                                <a href={`tel:${el.phone}`}>
                                  {el.phone}
                                </a>
                              </Typography></>}

                              <br />
                              {el.phone && <><Typography variant="overline">
                                DATE OF JOINING
                              </Typography>
                              <Typography variant="body1">
                                
                                  {el.date_of_joining}
                               
                              </Typography></>}
                            </div>
                          </CardBody>
                        </Card>
                        <br />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
